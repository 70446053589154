import React from "react";
import { useContext, useState, useEffect } from "react";
import { useNavigate, Link, useLocation, Outlet } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import MainContext from "../context/MainContext";
import GenByFuel from "./GenByFuel";
import { Chart } from "react-google-charts";

const MainLayout = () => {
  const navigate = useNavigate();
  const { datePicker, SetDatePicker, loading } = useContext(MainContext);

  const onCCGTClicked = () => navigate("/bmu/CCGT");
  const onWindClicked = () => navigate("/bmu/WIND");
  const onNuclearClicked = () => navigate("/bmu/NUCLEAR");
  const onBiomassClicked = () => navigate("/bmu/BIOMASS");
  const onNpshydClicked = () => navigate("/bmu/NPSHYD");
  const onPsClicked = () => navigate("/bmu/PS");
  const onOcgtClicked = () => navigate("/bmu/OCGT");
  const onCoalClicked = () => navigate("/bmu/COAL");
  const onOtherClicked = () => navigate("/bmu/OTHER");
  const onNonEClicked = () => navigate("/bmu/nullE");
  const onNonSClicked = () => navigate("/bmu/nullS");
  const onNonVClicked = () => navigate("/bmu/nullV");
  const onNonTClicked = () => navigate("/bmu/nullT");
  const onNonGClicked = () => navigate("/bmu/nullG");

  const onUOU2t14dCCGTClicked = () => navigate("/uou2t14d/CCGT");
  const onUOU2t14dWindClicked = () => navigate("/uou2t14d/WIND");
  const onUOU2t14dNuclearClicked = () => navigate("/uou2t14d/NUCLEAR");
  const onUOU2t14dBiomassClicked = () => navigate("/uou2t14d/BIOMASS");
  const onUOU2t14dNpshydClicked = () => navigate("/uou2t14d/NPSHYD");
  const onUOU2t14dPsClicked = () => navigate("/uou2t14d/PS");
  const onUOU2t14dOcgtClicked = () => navigate("/uou2t14d/OCGT");
  const onUOU2t14dOtherClicked = () => navigate("/uou2t14d/OTHER");

  const onUOU2t3ywCCGTClicked = () => navigate("/uou2t3yw/CCGT");
  const onUOU2t3ywWindClicked = () => navigate("uou2t3yw/WIND");
  const onUOU2t3ywNuclearClicked = () => navigate("/uou2t3yw/NUCLEAR");
  const onUOU2t3ywBiomassClicked = () => navigate("/uou2t3yw/BIOMASS");
  const onUOU2t3ywNpshydClicked = () => navigate("/uou2t3yw/NPSHYD");
  const onUOU2t3ywPsClicked = () => navigate("/uou2t3yw/PS");
  const onUOU2t3ywOcgtClicked = () => navigate("/uou2t3yw/OCGT");
  const onUOU2t3ywOtherClicked = () => navigate("/uou2t3yw/OTHER");

  const onMeteredCCGTClicked = () => navigate("/metered/CCGT");
  const onMeteredWindClicked = () => navigate("metered/WIND");
  const onMeteredNuclearClicked = () => navigate("/metered/NUCLEAR");
  const onMeteredBiomassClicked = () => navigate("/metered/BIOMASS");
  const onMeteredNpshydClicked = () => navigate("/metered/NPSHYD");
  const onMeteredPsClicked = () => navigate("/metered/PS");
  const onMeteredOcgtClicked = () => navigate("/metered/OCGT");
  const onMeteredOtherClicked = () => navigate("/metered/OTHER");

  const onDatePickerUpdated = (e) => {
    SetDatePicker(e.target.value);
    console.log(e.target.value);
  };
  const onFuelClicked = () => navigate("/fuel/");
  const onFuel2Clicked = () => navigate("/fuel2/");
  const onDemandOuturnClicked = () => navigate("/system/");
  const onDisbsadClicked = () => navigate("/disbsad/");
  const onSbpsspClicked = () => navigate("/sbpssp/");
  const onBidOfferClicked = () => navigate("/system2/");
  const onWindForecastClicked = () => navigate("/wind/");
  const onDerateClicked = () => navigate("/derate/");
  const onDerate2Clicked = () => navigate("/derate2/");
  const onOcfm2_156Clicked = () => navigate("/ocfm2156/");
  const onOcfmd2Clicked = () => navigate("/ocfmd2/");
  const onMidClicked = () => navigate("/mid/");
  const onSosoClicked = () => navigate("/soso/");
  const onWarningsClicked = () => navigate("/warning/");

  const dateSelector = loading ? null : (
    <input
      type="date"
      id="datePicker"
      value={datePicker}
      onChange={onDatePickerUpdated}
    ></input>
  );
  let NavTitle = (
    <div>
      BMU 2 to 156 <br /> Week Usable Output
    </div>
  );

  return (
    <>
      {/* <Navbar expand="lg" className="bg-body-tertiary" placement="sticky-top"> */}
      <Navbar
        className="sticky-nav"
        expand="lg"
        colorscheme="light"
        style={{ backgroundColor: "#e3f2fd" }}
        //sticky="top"
        fixed="top"
      >
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {dateSelector}

              {loading ? (
                <Nav.Link>Loading...</Nav.Link>
              ) : (
                <NavDropdown title="BMU by Fuel" id="basic-nav-dropdown">
                  <NavDropdown.Item onClick={onCCGTClicked}>
                    CCGT
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={onOcgtClicked}>
                    OCGT
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={onWindClicked}>
                    Wind
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={onBiomassClicked}>
                    Biomass
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={onNuclearClicked}>
                    Nuclear
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={onPsClicked}>
                    Pump Storage
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={onNpshydClicked}>
                    Non Pump Storage
                  </NavDropdown.Item>

                  <NavDropdown.Item onClick={onCoalClicked}>
                    Coal
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={onOtherClicked}>
                    Other
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={onNonEClicked}>
                    Non Allocated Fuel BMU Type E
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={onNonSClicked}>
                    Non Allocated Fuel BMU Type S
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={onNonVClicked}>
                    Non Allocated Fuel BMU Type V
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={onNonTClicked}>
                    Non Allocated Fuel BMU Type T
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={onNonGClicked}>
                    Non Allocated Fuel BMU Type G
                  </NavDropdown.Item>
                </NavDropdown>
              )}
              {loading ? null : (
                <NavDropdown title="Gen By Fuel" id="basic-nav-dropdown2">
                  <NavDropdown.Item onClick={onFuel2Clicked}>
                    Gen By Fuel with BOA
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={onFuelClicked}>
                    Gen By Fuel Alt
                  </NavDropdown.Item>
                </NavDropdown>
              )}
              {loading ? null : (
                <NavDropdown title="System Data" id="basic-nav-dropdown3">
                  <NavDropdown.Item onClick={onDemandOuturnClicked}>
                    Demand/Outturn
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={onSbpsspClicked}>
                    System Buy Price Sell Price
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={onDisbsadClicked}>
                    Bisbsad
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={onBidOfferClicked}>
                    System Bid Offer Data
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={onMidClicked}>
                    Market Index Data
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={onWindForecastClicked}>
                    Wind Forecast
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={onDerateClicked}>
                    Derate Margin
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={onDerate2Clicked}>
                    Derate Margin Horizon
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={onSosoClicked}>
                    SOSO Trade Data
                  </NavDropdown.Item>
                </NavDropdown>
              )}

              {loading ? null : (
                <NavDropdown
                  title={"Availability Forecasts"}
                  id="basic2-nav-dropdown"
                >
                  <NavDropdown
                    title=" OCFM Margin Data "
                    id="basic-nav-dropdown4"
                  >
                    <NavDropdown.Item onClick={onOcfmd2Clicked}>
                      Day 2 to 14
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={onOcfm2_156Clicked}>
                      Week 2 to 156
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown
                    title={"BMU 2 to 14 Day Usable Output"}
                    id="basic3-nav-dropdown"
                  >
                    <NavDropdown.Item onClick={onUOU2t14dCCGTClicked}>
                      CCGT
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={onUOU2t14dOcgtClicked}>
                      OCGT
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={onUOU2t14dWindClicked}>
                      Wind
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={onUOU2t14dBiomassClicked}>
                      Biomass
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={onUOU2t14dNuclearClicked}>
                      Nuclear
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={onUOU2t14dPsClicked}>
                      Pump Storage
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={onUOU2t14dNpshydClicked}>
                      Non Pump Storage
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={onUOU2t14dOtherClicked}>
                      Other
                    </NavDropdown.Item>

                    <NavDropdown.Item onClick={onUOU2t14dOtherClicked}>
                      Other
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown
                    title="BMU 2 to 156 Week Usable Output"
                    id="basic2-nav-dropdown"
                  >
                    <NavDropdown.Item onClick={onUOU2t3ywCCGTClicked}>
                      CCGT
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={onUOU2t3ywOcgtClicked}>
                      OCGT
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={onUOU2t3ywWindClicked}>
                      Wind
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={onUOU2t3ywBiomassClicked}>
                      Biomass
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={onUOU2t3ywNuclearClicked}>
                      Nuclear
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={onUOU2t3ywPsClicked}>
                      Pump Storage
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={onUOU2t3ywNpshydClicked}>
                      Non Pump Storage
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={onUOU2t3ywOtherClicked}>
                      Other
                    </NavDropdown.Item>
                  </NavDropdown>
                </NavDropdown>
              )}

              {loading ? null : (
                <NavDropdown title="Metered Output" id="basic3-nav-dropdown">
                  <NavDropdown.Item onClick={onMeteredCCGTClicked}>
                    CCGT
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={onMeteredOcgtClicked}>
                    OCGT
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={onMeteredWindClicked}>
                    Wind
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={onMeteredBiomassClicked}>
                    Biomass
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={onMeteredNuclearClicked}>
                    Nuclear
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={onMeteredPsClicked}>
                    Pump Storage
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={onMeteredNpshydClicked}>
                    Non Pump Storage
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={onMeteredOtherClicked}>
                    Other
                  </NavDropdown.Item>
                </NavDropdown>
              )}
              {loading ? null : (
                <NavDropdown title="Warnings" id="basic5-nav-dropdown">
                  <NavDropdown.Item onClick={onWarningsClicked}>
                    Warning Messages
                  </NavDropdown.Item>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet />
    </>
  );
};

export default MainLayout;
